import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button } from 'antd';

import {Banner} from '../../../../components/Banner/Banner';
import {INDEX} from '../../../../config/routes';

import cls from './Success.module.scss'


export const SuccessNonReturn = () => {

    const navigate = useNavigate()

    return (
    <div className={cls.page}>
        <div className={'content'}>
            <div className={cls.text}>
                <h2>Инструкция по возврату</h2>
                <p>
                    Уважаемый Клиент,<br/>
                    Группа компаний «FTS Group» благодарит Вас за обращение.
                </p>
                <p>
                    Средства уплаченные за уникальный код Сертификата возврату не подлежат.
                </p>
                <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
            </div>
        </div>

        <Banner/>

    </div>
)}


