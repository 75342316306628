import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'antd';

import {Banner} from '../../../../components/Banner/Banner';
import {INDEX} from '../../../../config/routes';

import cls from './Success.module.scss'


export const SuccessOnPersonal = () => {

    const navigate = useNavigate()

    return (
        <div className={cls.page}>
            <div className={'content'}>
                <div className={cls.text}>
                    <h2>Инструкция по возврату</h2>
                    <p>
                        Уважаемый Клиент,<br/>
                        Группа компаний «FTS Group» благодарит Вас за обращение.
                    </p>
                    <p>
                        Для оформления расторжения по данному сертификату необходимо направить заявку в кредитную организацию на адрес электронной почты <a href={'mailto:vozvrat@multypolisnal.ru'}>vozvrat@multypolisnal.ru</a><br/>
                        Так же информацию по возврату вы можете уточнить по номеру горячей линии <a href={'tel:88005000890'}>8 800 500 08 90</a>
                    </p>
                    <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
                </div>
            </div>

            <Banner/>

        </div>
    )
}


