import {Button, ConfigProvider} from 'antd';

import cover from './cover.jpg'
import coverH from './coverH.jpg'
import logo from './OnlyCashLogo.svg'

import cls from './OnlyCash.module.scss'

const url = 'https://onlycash.tech/?utm_source=fts&utm_medium=banner'

interface IOnlyCash {
    horizontal?: boolean
    onClick?: () => void
}

export const OnlyCash = ({horizontal, onClick}: IOnlyCash) => (
    <div className={cls.wrapper}>
        <a onClick={onClick} href={url} className={cls.content} style={{
            backgroundImage: horizontal ? `url(${coverH})` : `url(${cover})`,
        }}>
            <div className={cls.logo}>
                <img src={logo} alt={'OnlyCash Logo'}/>
            </div>
            <div className={cls.headline}>
                Получите онлайн займ под 0,8%
            </div>
            <div>
                <ConfigProvider theme={{
                    token: {
                        colorPrimary: '#B3598F',
                    },
                }}>
                    <Button type={'primary'} className={cls.button}>Получить займ</Button>
                </ConfigProvider>
            </div>
        </a>
        <div className={cls.disclamer}>
            Не оферта. Проект является агрегатором финансовых услуг, представленных различными кредитными организациями,
            которые имеют все обязательные лицензии и сертификаты. Реклама. Рекламодатель: ООО «Гет Мани» ИНН 9724148897
        </div>
    </div>
)